import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators } from 'redux';

import trans from 'common/tools/translations/trans';

import {
  addFollowee,
  removeFollowee,
  TrackingContextParam
} from 'website/actions/RelationshipActions';
import onlyIfHasSocial from 'website/components/HoC/onlyIfHasSocial';
import { State, Dispatch } from 'website/reducers';

import AuthenticatedLink from './AuthenticatedLink';
import styles from './styles.module.scss';

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = {
  targetUser: string;
};

const FollowUnfollowButton = ({
  isFollowee = false,
  targetUser,
  removeFollowee,
  addFollowee
}: Props & PropsFromRedux) => {
  const handleClick = () => {
    const trackingContext: TrackingContextParam = {
      socialActionPlacement: 'standalone'
    };

    return isFollowee
      ? removeFollowee(targetUser, trackingContext)
      : addFollowee(targetUser, trackingContext);
  };

  const content = isFollowee
    ? trans('moviepage_reviews_users.unfollow')
    : trans('moviepage_reviews_users.follow');

  return (
    <AuthenticatedLink
      className={styles.followUnfollowButton}
      onClick={handleClick}
    >
      {content}
    </AuthenticatedLink>
  );
};

const mapStateToProps = (state: State, ownProps: Props) => {
  const isFollowee = !!state.user.relationships?.followees.find(
    (candidate: string) => candidate === ownProps.targetUser
  );
  return {
    isFollowee
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      addFollowee,
      removeFollowee
    },
    dispatch
  );

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(onlyIfHasSocial(FollowUnfollowButton));
