import React from 'react';

import Button from 'common/components/Button';
import { TrackingEventNames } from 'common/constants/trackingEventsNames';
import eventEmitter from 'common/services/events/eventEmitter';
import { path } from 'common/tools/network/routing';
import trans, { transChoice } from 'common/tools/translations/trans';

import WantToSeeButton from 'website/components/WantToSeeButton';
import AuthenticatedLink from 'website/components/user/AuthenticatedLink';
import { Entity, EntityTypename } from 'website/types';

import styles from './styles.module.scss';

export enum TooltipType {
  Login,
  BadScore,
  PoorScore,
  GoodScore,
  ExcellentScore,
  OngoingAlgo,
  NotEnoughRatings,
  NotEligible
}

type Props = {
  tooltipType?: TooltipType;
  entity: Entity;
};

const AffinityTooltip = ({ tooltipType, entity }: Props) => {
  const entityTypeCode = entity.typename === EntityTypename.Series ? 1 : 0;

  switch (tooltipType) {
    case TooltipType.Login:
      return (
        <>
          <span className={styles.affinityScoreTooltipText}>
            {transChoice('affinities.score.tooltip.login', entityTypeCode, {
              count: entityTypeCode
            })}
          </span>
          <AuthenticatedLink
            trackingContext={{
              eventAction: 'click_tooltip',
              entityTypename: entity.typename
            }}
          >
            <Button size="sm" theme="default">
              {trans('account.create.log-yourself')}
            </Button>
          </AuthenticatedLink>
        </>
      );
    case TooltipType.BadScore:
      return (
        <span>
          {transChoice('affinities.score.tooltip.badScore', entityTypeCode, {
            count: entityTypeCode
          })}
        </span>
      );
    case TooltipType.PoorScore:
      return (
        <span>
          {transChoice('affinities.score.tooltip.poorScore', entityTypeCode, {
            count: entityTypeCode
          })}
        </span>
      );
    case TooltipType.GoodScore:
      return (
        <>
          <span className={styles.affinityScoreTooltipText}>
            {transChoice('affinities.score.tooltip.goodScore', entityTypeCode, {
              count: entityTypeCode
            })}
          </span>
          <WantToSeeButton
            size="sm"
            theme="default"
            entityId={entity.id}
            socialActionPlacement="affinity_score"
            className={styles.affinityScoreTooltipButton}
          >
            {trans('bam.push.want-see-yes')}
          </WantToSeeButton>
        </>
      );
    case TooltipType.ExcellentScore:
      return (
        <>
          <span className={styles.affinityScoreTooltipText}>
            {transChoice(
              'affinities.score.tooltip.excellentScore',
              entityTypeCode,
              { count: entityTypeCode }
            )}
          </span>
          <WantToSeeButton
            size="sm"
            theme="default"
            entityId={entity.id}
            socialActionPlacement="affinity_score"
            className={styles.affinityScoreTooltipButton}
          >
            {trans('bam.push.want-see-yes')}
          </WantToSeeButton>
        </>
      );
    case TooltipType.OngoingAlgo:
      return <span>{trans('affinities.score.tooltip.ongoingAlgo')}</span>;
    case TooltipType.NotEnoughRatings:
      return (
        <>
          <span className={styles.affinityScoreTooltipText}>
            {trans('affinities.score.tooltip.notEnoughRatings')}
          </span>
          <Button
            size="sm"
            theme="default"
            href={path('userspace_permanent_speednoter')}
            onClick={() => {
              eventEmitter.emit(
                TrackingEventNames.OPEN_PERMANENT_SPEEDNOTER_BUTTON_CLICK,
                entity
              );
            }}
          >
            {trans('affinities.score.tooltip.startSpeedNoter')}
          </Button>
        </>
      );
    case TooltipType.NotEligible:
      return <span>{trans('affinities.score.tooltip.notEligible')}</span>;
    default:
      return null;
  }
};

export default AffinityTooltip;
