import Cookies from 'cookies-js';

import { GEOLOCALISATION_GEOCODE } from 'common/constants/CookieNames';
import eventEmitter, { EventsTypes } from 'common/services/events/eventEmitter';
import { hasClass } from 'common/tools/dom/classes';
import { on } from 'common/tools/dom/events';
import readAttribute from 'common/tools/dom/readAttribute';
import { path } from 'common/tools/network/routing';
import { runAfterDesobfuscation } from 'common/tools/seo/desobfuscation';

const FAVORITE_THEATERS_LINK_CLASS = 'js-showtimes-nav-favorite-theater';
const LOCALIZATION_THEATER_LINK_CLASS = 'js-showtimes-nav-localization';
const SHOWTIMES_KNP_LINK_ID = 'js-showtimes-nav-redir';

const LS_SHOWTIMES_USER_NAV = 'showtimes_user_nav';
const LS_SHOWTIMES_USER_FAVORITES = 'showtimes_user_favorites_theaters';
const LS_SHOWTIMES_USER_LOCALIZATION = 'showtimes_user_localization_theaters';

export function changeShowtimeLink() {
  const domElement = document.getElementsByClassName('seance-geoloc-redir')[0];
  if (!domElement) return;
  const entityId = readAttribute(domElement, 'data-seance-geoloc-redir');

  // wait for the end of desobfuscation
  runAfterDesobfuscation(() => {
    // In showtimes navigation bar, both links (localization and favorites) are set from the twig
    // So we dont have to wait for the geocode to handle the user preference
    saveUserShowtimePreference();

    // Also we can handle as soon as possible the KNP link in case of this one have to be set on favorit theaters
    changeKnpShowtimesLink(entityId);

    // Once we have the geocode result we can reset all those link for the user is necessary (relaunch the function)
    // NDLR => here we will rewrite 2 times the href for KNP link. We should re-think this way to handle it.
    if (Cookies.get(GEOLOCALISATION_GEOCODE)) {
      changeKnpShowtimesLink(entityId);
      changeLocalizationShowtimesLinks(entityId);
    } else {
      eventEmitter.on(EventsTypes.GEOLOCATION_AVAILABLE, () => {
        changeKnpShowtimesLink(entityId);
        changeLocalizationShowtimesLinks(entityId);
      });
    }
  });
}

function changeKnpShowtimesLink(id) {
  const link = document.getElementById(SHOWTIMES_KNP_LINK_ID);

  if (!link) {
    return;
  }

  let href = setLocalizationHref(id);

  if (getUserShowtimePreferenceLocalStorage() === LS_SHOWTIMES_USER_FAVORITES) {
    href = setFavoritesHref(id);
  }

  link.href = href;
}

function changeLocalizationShowtimesLinks(id) {
  const links = document.querySelectorAll(
    `.${LOCALIZATION_THEATER_LINK_CLASS}`
  );

  if (!links.length) {
    return;
  }

  links.forEach(item => {
    item.href = setLocalizationHref(id);
  });
}

function setLocalizationHref(id) {
  const geocode = Cookies.get(GEOLOCALISATION_GEOCODE);

  if (!geocode) {
    return path('moviepage_showtimes', { movie: id });
  }

  return path('moviepage_showtimes_place', {
    movie: id,
    localization: geocode
  });
}

function setFavoritesHref(id) {
  return path('moviepage_showtimes_my_theaters', {
    movie: id
  });
}

function saveUserShowtimePreference() {
  const showtimesTheaterLinks = document.querySelectorAll(
    `.${FAVORITE_THEATERS_LINK_CLASS},
    .${LOCALIZATION_THEATER_LINK_CLASS}`
  );

  showtimesTheaterLinks.forEach(item => {
    on(item, 'click', setUserShowtimePreferenceLocalStorage.bind(null, item));
  });
}

function setUserShowtimePreferenceLocalStorage(element) {
  let showtimeUserNav = LS_SHOWTIMES_USER_LOCALIZATION;

  if (hasClass(element, FAVORITE_THEATERS_LINK_CLASS)) {
    showtimeUserNav = LS_SHOWTIMES_USER_FAVORITES;
  }

  localStorage.setItem(LS_SHOWTIMES_USER_NAV, showtimeUserNav);
}

function getUserShowtimePreferenceLocalStorage() {
  return (
    localStorage.getItem(LS_SHOWTIMES_USER_NAV) ||
    LS_SHOWTIMES_USER_LOCALIZATION
  );
}
