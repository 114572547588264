export default async function init() {
  const anchorFavorite = document.getElementById(
    'moviepage-favorite-showtimes-ui'
  );
  const anchorProximity = document.getElementById(
    'moviepage-showtimes-promximity-ui'
  );
  const anchorProximityFuture = document.getElementById(
    'moviepage-showtimes-promximity-ui-future'
  );

  if (anchorFavorite) {
    const module = await import(
      /* webpackChunkName: "favorite-showtimes" */ './moviepageMyTheaters'
    );
    module.init(anchorFavorite);
  }

  if (anchorProximity) {
    const module = await import(
      /* webpackChunkName: "proximity-showtimes" */ './moviepageProximity'
    );
    module.init(anchorProximity);
  }

  if (anchorProximityFuture) {
    const module = await import(
      /* webpackChunkName: "proximity-future-showtimes" */ './moviepageProximityFuture'
    );
    module.init(anchorProximityFuture);
  }
}
