import * as events from 'common/tools/dom/events';
import readAttribute from 'common/tools/dom/readAttribute';

import { openExportPlayerModal } from 'website/services/modal';

export default function init() {
  const btnExportPlayer = document.getElementById('btn-export-player');

  if (btnExportPlayer) {
    const playerConf = readAttribute(btnExportPlayer, 'data-model');

    const videoData = {
      ...playerConf,
      ...(playerConf.videos ? playerConf.videos[0] : {})
    };

    const title = btnExportPlayer.getAttribute('data-title');

    const openModal = event => {
      event.preventDefault();

      openExportPlayerModal({
        title,
        videoData
      });
    };

    events.on(btnExportPlayer, 'click', openModal);
  }
}
