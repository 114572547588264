import {
  retrieveUserFollowees,
  createFollowee,
  deleteFollowee
} from 'common/api/GraphApi';
import { SocialActionPlacements } from 'common/constants/trackingEventsNames';

import * as actionTypes from 'website/constants/ActionTypes';

export type TrackingContextParam = {
  socialActionPlacement: SocialActionPlacements;
};

/**
 * Ask the api to retrieve the list of followers for the current user
 * @param  {Array} userIds - User ids for which we need to know if they are followees
 * @return {Object} - the action to dispatch
 */
export const getUserFollowees = (userIds: string[]) => ({
  types: [
    actionTypes.GET_USER_FOLLOWEES_REQUEST,
    actionTypes.GET_USER_FOLLOWEES_SUCCESS,
    actionTypes.GET_USER_FOLLOWEES_FAILURE
  ],
  callAPI: () => retrieveUserFollowees(userIds),
  payload: {}
});

/**
 * Ask the API to create a new followee for the current user
 * @param  {String} userId The target user id
 * @param  {Object} context - tracking data to fetch
 * @return {Object} - the action to dispatch
 */
export const addFollowee = (userId: string, context: TrackingContextParam) => ({
  types: [
    actionTypes.CREATE_FOLLOWEE_REQUEST,
    actionTypes.CREATE_FOLLOWEE_SUCCESS,
    actionTypes.CREATE_FOLLOWEE_FAILURE
  ],
  callAPI: () => createFollowee(userId, context),
  payload: { userId }
});

/**
 * Ask the api to delete a followee
 * @param  {Object} userId userId
 * @param  {Object} context - tracking data to fetch
 * @return {Object} - the action to dispatch
 */
export const removeFollowee = (
  userId: string,
  context: TrackingContextParam
) => ({
  types: [
    actionTypes.DELETE_FOLLOWEE_REQUEST,
    actionTypes.DELETE_FOLLOWEE_SUCCESS,
    actionTypes.DELETE_FOLLOWEE_FAILURE
  ],
  callAPI: () => deleteFollowee(userId, context),
  payload: { userId }
});
