import Cookies from 'cookies-js';

import eventEmitter, { EventsTypes } from 'common/services/events/eventEmitter';
import { getRootDomain } from 'common/tools/network/url';

export function savePreferedGeolocation(location) {
  if (typeof location.path === 'undefined') {
    return;
  }
  deleteAllGeolocationCookies();
  for (const [cookieName, cookieValue] of computeGeolocationCookies(
    location.path
  )) {
    Cookies.set(cookieName, cookieValue, {
      expires: 24 * 60 * 60 * 365,
      domain: '.' + getRootDomain(window.location.hostname)
    });
  }
  eventEmitter.emit(EventsTypes.GEOLOCATION_AVAILABLE);
}

export function savePreferedGeocodeOnly(name, value) {
  if (!window.location) {
    return false;
  }

  deleteAllGeolocationCookies();
  Cookies.set(name, value, {
    expires: 24 * 60 * 60 * 365,
    domain: '.' + getRootDomain(window.location.hostname)
  });
}

export function deleteAllGeolocationCookies() {
  const cookies = document.cookie.split(';');

  for (let index = 0; index < cookies.length; index++) {
    const cookie = cookies[index];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    if (name.match(/geolevel\d|geocode/)) {
      document.cookie =
        name +
        '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;domain=.' +
        getRootDomain(window.location.hostname) +
        ';path=/';
    }
  }
}

export function computeGeolocationCookies(pathValues) {
  const geoCookies = new Map();
  pathValues.forEach((geocode, index) => {
    let cookieName = 'geolevel' + (index + 1);
    if (index + 1 === pathValues.length) {
      cookieName = 'geocode';
    }
    geoCookies.set(cookieName, geocode);
  });
  return geoCookies;
}
