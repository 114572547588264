import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  createOpinionOnReview as _createOpinionOnReview,
  deleteOpinionOnReview as _deleteOpinionOnReview
} from 'website/actions/SocialActionActions';
import onlyIfHasSocial from 'website/components/HoC/onlyIfHasSocial';
import AuthenticatedLink from 'website/components/user/AuthenticatedLink';
import {
  actionIsRecent,
  getHelpfulForEntity,
  getUnhelpfulForEntity
} from 'website/reducers/data/helper';

class HelpfulUnhelpfulButtons extends Component {
  handleClick(type, event) {
    event.preventDefault();
    if (this.props[type + 'Action']) {
      this.props.deleteOpinionOnReview(this.props.opinion, type);
    } else {
      this.props.createOpinionOnReview(this.props.opinion, type);
    }
  }

  computeCount() {
    const {
      statistics: { helpfulCount, unhelpfulCount }
    } = this.props;
    const { helpfulAction, unhelpfulAction } = this.props;
    return {
      helpfulCount:
        helpfulCount + (helpfulAction && actionIsRecent(helpfulAction) ? 1 : 0),
      unhelpfulCount:
        unhelpfulCount +
        (unhelpfulAction && actionIsRecent(unhelpfulAction) ? 1 : 0)
    };
  }

  render() {
    const { helpfulCount, unhelpfulCount } = this.computeCount();

    return (
      <AuthenticatedLink>
        <a
          href="#"
          className={classNames('button button-xs button-helpful', {
            active: this.props.helpfulAction
          })}
          onClick={this.handleClick.bind(this, 'helpful')}
        >
          <i className="icon icon-thumb-up">
            <span className="no-icon">yes</span>
          </i>
          <span className="txt">{helpfulCount}</span>
        </a>
        <a
          href="#"
          className={classNames('button button-xs button-helpful', {
            active: this.props.unhelpfulAction
          })}
          onClick={this.handleClick.bind(this, 'unhelpful')}
        >
          <i className="icon icon-thumb-down">
            <span className="no-icon">no</span>
          </i>
          <span className="txt">{unhelpfulCount}</span>
        </a>
      </AuthenticatedLink>
    );
  }
}

HelpfulUnhelpfulButtons.propTypes = {
  helpfulAction: PropTypes.any,
  statistics: PropTypes.shape({
    helpfulCount: PropTypes.number.isRequired,
    unhelpfulCount: PropTypes.number.isRequired
  }).isRequired,
  unhelpfulAction: PropTypes.any,
  opinion: PropTypes.object,
  createOpinionOnReview: PropTypes.func.isRequired,
  deleteOpinionOnReview: PropTypes.func.isRequired
};

HelpfulUnhelpfulButtons.defaultProps = {
  opinion: null,
  helpfulAction: null,
  unhelpfulAction: null
};

function mapStateToProps(state, ownProps) {
  const opinion = state.data.all[ownProps.opinionId];
  const helpfulAction = getHelpfulForEntity(ownProps.opinionId, state.data);
  const unhelpfulAction = getUnhelpfulForEntity(ownProps.opinionId, state.data);
  return {
    opinion: opinion,
    helpfulAction: helpfulAction,
    unhelpfulAction: unhelpfulAction
  };
}

const mapDispatchToProps = dispatch => ({
  createOpinionOnReview: (...args) => dispatch(_createOpinionOnReview(...args)),
  deleteOpinionOnReview: (...args) => dispatch(_deleteOpinionOnReview(...args))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(onlyIfHasSocial(HelpfulUnhelpfulButtons));
