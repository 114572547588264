import React from 'react';
import { createRoot } from 'react-dom/client';

import readAttribute from 'common/tools/dom/readAttribute';

import { addEntities } from 'website/actions/EntityActions';
import { getOpinionOnReviews } from 'website/actions/OpinionActions';
import { getUserFollowees } from 'website/actions/RelationshipActions';
import FollowUnfollowButton from 'website/components/user/FollowUnfollowButton';
import HelpfulUnhelpfulButtons from 'website/components/user/HelpfulUnhelpfulButtons';
import allocineContainer from 'website/containers/allocineContainer';
import { Dispatch } from 'website/reducers';
import store from 'website/store';

import { waitForLoginStatus } from './connection';

export default async () => {
  const followAnchors = document.getElementsByClassName('js-follow-unfollow');

  const ConnectedFollowUnfollowButton = allocineContainer(FollowUnfollowButton);

  let userIds: string[] = [];

  for (let i = 0; i < followAnchors.length; i++) {
    const targetUser = readAttribute<string, undefined>(
      followAnchors[i],
      'data-targetuserid'
    );
    if (targetUser) {
      userIds = [...userIds, targetUser];
      const root = createRoot(followAnchors[i]);
      root.render(<ConnectedFollowUnfollowButton targetUser={targetUser} />);
    }
  }

  const helpfulAnchors = document.getElementsByClassName('js-useful-reviews');

  const ConnectedHelpfulUnhelpfullButtons = allocineContainer(
    HelpfulUnhelpfulButtons
  );

  let opinionIds: string[] = [];

  for (let i = 0; i < helpfulAnchors.length; i++) {
    const statistics = readAttribute(helpfulAnchors[i], 'data-statistics');
    const legacyOpinionId = readAttribute<number, undefined>(
      helpfulAnchors[i],
      'data-opinionid'
    )?.toString();
    const opinionId = window.btoa(`UserReview:${legacyOpinionId}`);
    opinionIds = [...opinionIds, opinionId];
    const root = createRoot(helpfulAnchors[i]);
    root.render(
      <ConnectedHelpfulUnhelpfullButtons
        statistics={statistics}
        opinionId={opinionId}
      />
    );
  }

  // For some obscure reason we can't add UserReviews to the normal
  // jsEntity workflow. It's because of a mixup between UserReview and Opinion
  // Graph type. As a consequence, we register reviews here.
  store.dispatch(
    addEntities(
      opinionIds.reduce(
        <T extends string>(acc: Record<T, { id: T }>, id: T) => {
          acc[id] = {
            id
          };
          return acc;
        },
        {}
      )
    )
  );

  const user = await waitForLoginStatus();
  if (user.loggedIn) {
    // Fetching the necessary opinions on reviews
    if (helpfulAnchors.length) {
      (store.dispatch as Dispatch)(getOpinionOnReviews(opinionIds));
    }

    if (followAnchors.length) {
      (store.dispatch as Dispatch)(getUserFollowees(userIds));
    }
  }
};
