export default async function init() {
  const historySupport = window.history.pushState;
  const galleryElement = document.getElementById('js-async-gallery');
  const galleryElementGql = document.getElementById('js-async-gallery-gql');

  if (!historySupport) {
    return;
  }

  if (galleryElement) {
    const module = await import(
      /* webpackChunkName: "async-gallery" */ './asyncGallery.lazy'
    );
    module.initGallery(galleryElement);
  }

  if (galleryElementGql) {
    const module = await import(
      /* webpackChunkName: "async-gallery-gql" */ './asyncGalleryGql.lazy'
    );
    module.initGallery(galleryElementGql);
  }
}
