import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import {
  getWantToSeeForEntity,
  actionIsRecent as _actionIsRecent
} from 'website/reducers/data/helper';

import WantToSeeButton from './WantToSeeButton';

const EntityTitleActions = ({
  wantToSeeCount,
  isComingSoon,
  entityId,
  actionIsRecent
}) => {
  if (isComingSoon) {
    const count = actionIsRecent ? wantToSeeCount + 1 : wantToSeeCount;
    const buttonClass = classNames('icon', 'icon-wish-full', {
      'button-no-counter': !count
    });

    return (
      <WantToSeeButton
        theme="social-ac"
        type="ghost"
        size="sm"
        className={buttonClass}
        entityId={entityId}
      >
        {count ? <span className="counter">{count}</span> : null}
      </WantToSeeButton>
    );
  }

  return null;
};

EntityTitleActions.propTypes = {
  entityId: PropTypes.string.isRequired,
  wantToSeeCount: PropTypes.number,
  isComingSoon: PropTypes.bool,
  actionIsRecent: PropTypes.bool
};

EntityTitleActions.defaultProps = {
  wantToSeeCount: 0,
  isComingSoon: false,
  actionIsRecent: false
};

const mapStateToProps = (state, ownProps) => {
  const entity = state.data.all[ownProps.entityId];
  const wantToSeeAction = getWantToSeeForEntity(ownProps.entityId, state.data);
  return {
    actionIsRecent: wantToSeeAction && _actionIsRecent(wantToSeeAction),
    wantToSeeCount: entity.wantToSeeCount,
    isComingSoon: entity.isComingSoon,
    entityId: ownProps.entityId
  };
};

export default connect(mapStateToProps)(EntityTitleActions);
