export default async function init(): Promise<void> {
  const showMoreCastButtons = document.querySelectorAll('.js-fetch-cast');

  if (showMoreCastButtons.length) {
    const module = await import(
      /* webpackChunkName: "shots-fetch" */ './castFetch.lazy'
    );
    module.createCastFetch(showMoreCastButtons);
  }
}
