import loadScript from 'load-script';

import { BRAND_HAS_PAYWALL } from 'common/configuration/constants';
import { getConsentForGoogle } from 'common/consent/Didomi';
import { getConsentState } from 'common/consent/Tcf';
import { deferToLoad } from 'common/tools/dom/events';

const OUTBRAIN_URL = '//widgets.outbrain.com/outbrain.js';
const OUTBRAIN_URL_COOKIELESS = `${OUTBRAIN_URL}?i=fabcdb12`;

export default async function loadOutbrain() {
  const outbrainPlace = document.getElementsByClassName('js-outbrain');
  if (!outbrainPlace.length) {
    return false;
  }

  await Promise.all([getConsentState(), deferToLoad()]);

  // In that case CMP was validated but with a paiement and user disagree to all cookies
  if (BRAND_HAS_PAYWALL) {
    const googleConsent = await getConsentForGoogle();
    if (!googleConsent) {
      loadScript(OUTBRAIN_URL_COOKIELESS);
      return;
    }
  }

  loadScript(OUTBRAIN_URL);
}
