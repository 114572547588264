import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import AuthenticatedLink from 'website/components/user/AuthenticatedLink';
import { openReviewModal } from 'website/services/modal';
import { addToDatalayer } from 'website/tracking/googletagmanager';

class ReviewLink extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // This is a false positive due to new React getDerivedStateFromProps
      // TODO remove a soon as react eslint rules are updateds
      // eslint-disable-next-line react/no-unused-state
      passiveLoginDone: false
    };
    this.showModal = this.showModal.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.user.passiveLoginDone === true &&
      prevState.passiveLoginDone === false &&
      window.location &&
      window.location.hash === '#edit-review'
    ) {
      return {
        passiveLoginDone: true
      };
    }
    return null;
  }

  handleGtmLayer() {
    addToDatalayer(
      {
        event: 'eventGA',
        eventCategory: 'social-action',
        eventAction: 'critique'
      },
      {
        movie_distributor: 'movie_distributors',
        clicked_movie_id: 'movie_id'
      }
    );
  }

  showModal() {
    this.handleGtmLayer();
    openReviewModal({
      entityId: this.props.entityId,
      openOn: this.props.openOn
    });
  }

  render() {
    const { className, children } = this.props;
    return (
      <AuthenticatedLink className={className} onClick={this.showModal}>
        {children}
      </AuthenticatedLink>
    );
  }
}

ReviewLink.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string.isRequired,
  entityId: PropTypes.string.isRequired,
  openOn: PropTypes.string,
  // This is a false positive due to new React getDerivedStateFromProps
  // TODO remove a soon as react eslint rules are updateds
  // eslint-disable-next-line react/no-unused-prop-types
  user: PropTypes.object.isRequired
};

ReviewLink.defaultProps = {
  openOn: null
};

function mapStateToProps(state) {
  return {
    user: state.user
  };
}

export default connect(mapStateToProps)(ReviewLink);
