import React from 'react';
import { createRoot } from 'react-dom/client';

import readAttribute from 'common/tools/dom/readAttribute';

import AffinityScore from 'website/components/user/AffinityScore';
import allocineContainer from 'website/containers/allocineContainer';

export default function affinityBadge() {
  const anchors = document.getElementsByClassName('js-affinity-badge');

  if (anchors.length) {
    const ConnectedAffinityScore = allocineContainer(AffinityScore);
    for (const anchor of anchors) {
      const entityId = readAttribute<string, undefined>(
        anchor,
        'data-entity-id'
      );
      if (entityId) {
        const root = createRoot(anchor);
        root.render(<ConnectedAffinityScore entityId={entityId} />);
      }
    }
  }
}
