import { on } from 'common/tools/dom/events';
import readAttribute from 'common/tools/dom/readAttribute';
import { runAfterDesobfuscation } from 'common/tools/seo/desobfuscation';

import { setGeocodeCookieById } from 'website/api/Geocoding';
import { deleteAllGeolocationCookies } from 'website/services/geolocation/';

function handleChangeLocalization(links) {
  for (const link of links) {
    on(link, 'click', async e => {
      e.preventDefault();
      const dataLocalization = readAttribute(link, 'data-localization');

      try {
        await setGeocodeCookieById(dataLocalization.id, dataLocalization.type);
        window.location = link.href;
      } catch (e) {
        window.location = link.href;
      }
    });
  }
}

function handleDeleteLocalization(links) {
  for (const link of links) {
    on(link, 'click', deleteAllGeolocationCookies);
  }
}

export default function init() {
  runAfterDesobfuscation(() => {
    const changeLocalizationLinks = document.getElementsByClassName(
      'js-set-localization'
    );
    const deleteLocalizationLinks = document.getElementsByClassName(
      'js-delete-localization'
    );

    handleChangeLocalization(changeLocalizationLinks);
    handleDeleteLocalization(deleteLocalizationLinks);
  });
}
