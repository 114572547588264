import React from 'react';
import { connect } from 'react-redux';

import trans from 'common/tools/translations/trans';

import onlyIfHasSocial from 'website/components/HoC/onlyIfHasSocial';
import AuthenticatedLink from 'website/components/user/AuthenticatedLink';
import { State } from 'website/reducers';
import { openAddToCollectionModal } from 'website/services/modal';
import { Entity, SocialAction } from 'website/types';

type AddToCollectionButtonProps = {
  entityId: string;
  entity?: Entity | SocialAction;
};

const AddToCollectionButton = ({ entity }: AddToCollectionButtonProps) => {
  const handleClick = () => {
    openAddToCollectionModal({
      entity
    });
  };

  return (
    <AuthenticatedLink onClick={handleClick}>
      {trans('bam.more.add-to-collection')}
    </AuthenticatedLink>
  );
};

const mapStateToProps = (
  state: State,
  ownProps: AddToCollectionButtonProps
) => ({
  entity: state.data.all[ownProps.entityId]
});

export default connect(mapStateToProps)(onlyIfHasSocial(AddToCollectionButton));
