/* global DISQUS */
import { getDisqusToken } from 'common/api/SsoApi';
import eventEmitter, { EventsTypes } from 'common/services/events/eventEmitter';
import { deferToLoad, once } from 'common/tools/dom/events';
import hasTouch from 'common/tools/dom/getTouchBrowser';
import readAttribute from 'common/tools/dom/readAttribute';
import trans from 'common/tools/translations/trans';
import onUserAction from 'common/tools/ui/defer-on-user-action';
import { getSignInUrl, getSignOutUrl } from 'common/tools/userspace';
import { isString } from 'common/types';

import {
  DISQUS_API_KEY,
  DISQUS_SHORTNAME,
  DISQUS_SSO_BUTTON
} from 'website/configuration/constants';
import store from 'website/store/';
import loadDisqusScripts from 'website/third_party/disqus';

type DisqusThis = {
  page: {
    remote_auth_s3: string;
    api_key: string;
    identifier: string;
    category_id: string;
    url: string;
    shortname: string;
  };
  sso: {
    name: string;
    button: string;
    url: string;
    logout: string;
    width: string;
    height: string;
  };
};

type DisqusThreadInit = { threadId: string; category: string };

let loggedIn = false;
let threadId: string;
let category: string;
let token: string;
let resetConfiguration = false;
let refreshNeeded = false;
const btnShowDisqus = document.getElementById('btn-show-disqus');

function disqusConfig(this: DisqusThis) {
  if (token) {
    this.page.remote_auth_s3 = token;
  }

  this.page.api_key = DISQUS_API_KEY;

  this.page.identifier = threadId;
  this.page.category_id = category;
  this.page.url = window.location.href;
  this.page.shortname = DISQUS_SHORTNAME;

  this.sso = {
    name: trans('sitename'),
    button: DISQUS_SSO_BUTTON,
    url: getSignInUrl(window.location.href),
    logout: getSignOutUrl(window.location.href),
    width: '676',
    height: '361'
  };
}

async function refreshDisqusThread() {
  const state = store.getState();
  if (state.user.loggedIn !== loggedIn) {
    // user logged in, ask secure for a token
    loggedIn = !!state.user.loggedIn;
    const getDisqusTokenFunc = getDisqusToken;

    const tokenResponse = await getDisqusTokenFunc();
    token = tokenResponse.token;
    resetConfiguration = true;
  }

  if (resetConfiguration) {
    resetConfiguration = false;
    DISQUS.reset({
      reload: true,
      config: disqusConfig
    });
  }
}

type Payload = {
  uniqueDisqusThread: string;
  photo: string;
};

function changeThreadId(payload: Payload) {
  if (payload.uniqueDisqusThread || !threadId) {
    return;
  }
  const threadIdParts = threadId.split('-');
  const newthreadId = threadIdParts
    .slice(0, threadIdParts.length - 1)
    .concat([payload.photo])
    .join('-');
  threadId = newthreadId;
  resetConfiguration = true;
  refreshDisqusThread();
}

async function defer() {
  if (hasTouch()) {
    if (!btnShowDisqus) return Promise.reject();
    return deferToClick(btnShowDisqus);
  }

  await deferToLoad();

  return new Promise(resolve => {
    onUserAction(() => {
      resolve(null);
    });
  });
}

function deferToClick(element: HTMLElement) {
  return new Promise(resolve => {
    once(element, 'click', () => {
      resolve(null);
    });
  });
}

function loadScript() {
  return new Promise(resolve => {
    window.disqus_config = disqusConfig;
    loadDisqusScripts(threadId, category, () => {
      resolve(null);
    });
  });
}

export default function comments() {
  // don't do anything if the dom element is not available
  const disqusThread = document.getElementById('disqus_thread');

  if (!disqusThread) {
    return false;
  }

  const disqusThreadInit = readAttribute<DisqusThreadInit, undefined>(
    disqusThread,
    'data-init'
  );
  if (disqusThreadInit && !isString(disqusThreadInit)) {
    threadId = disqusThreadInit.threadId;
    category = disqusThreadInit.category;
  }

  // Wait either for click or window load to load script
  defer()
    .then(loadScript)
    .then(() => {
      eventEmitter.on(EventsTypes.LOGIN_SUCCESS, refreshDisqusThread);
      eventEmitter.on(EventsTypes.ASYNCHRONOUS_PAGE_CHANGE, changeThreadId);
      // Maybe the events where fired before disqus script loaded
      if (refreshNeeded) refreshDisqusThread();
    });

  eventEmitter.once(EventsTypes.LOGIN_SUCCESS, () => {
    refreshNeeded = true;
  });
  eventEmitter.once(EventsTypes.ASYNCHRONOUS_PAGE_CHANGE, () => {
    refreshNeeded = true;
  });
}
