import classnames from 'classnames';
import React from 'react';

import styles from './styles.module.scss';

// threshold of colors form 0 to 1 (red... [orange, apple, green])
export const thresholds = [0.2, 0.5, 0.8];

const colorThresholds = thresholds.map(value => value * 100);

const {
  circumference,
  diameter,
  padding,
  strokeDiameter,
  strokeRadius,
  strokeWidth
} = styles;
const radius = parseInt(diameter, 10) / 2;

const path = `M${radius} ${padding}a${strokeRadius} ${strokeRadius} 0 0 1 0 ${strokeDiameter}a${strokeRadius} ${strokeRadius} 0 0 1 0 -${strokeDiameter}`;
const viewBox = `0 0 ${diameter} ${diameter}`;

type Props = { affinityScore?: number | null; className?: string };

const AffinityBadge = ({ affinityScore, className }: Props) => {
  // help with the tutorial
  // https://medium.com/@pppped/how-to-code-a-responsive-circular-percentage-chart-with-svg-and-css-3632f8cd7705

  const isNumber = typeof affinityScore === 'number';
  const fixScore = Math.round(
    Math.min(Math.max(affinityScore || 0, 0), 1) * 100
  ); // remove negative and float values

  const strokeDasharray = `${
    (parseInt(circumference, 10) * fixScore) / 100
  } ${circumference}`;

  return (
    <div className={classnames(styles.badge, className)}>
      <svg viewBox={viewBox} className={styles.badgeSvg}>
        {/* dark circle background */}
        <circle cx={radius} cy={radius} r={radius} className={styles.badgeBg} />
        {/* grey ring (hidden if affinity has score) */}
        <circle
          cx={radius}
          cy={radius}
          r={strokeRadius}
          strokeWidth={strokeWidth}
          className={classnames(styles.badgeProgressRail, {
            [styles.active]: !isNumber
          })}
        />
        {/* colored progress ring */}
        <path
          className={classnames(styles.badgeProgressRing, {
            [styles.active]: isNumber,
            [styles.red]: fixScore <= colorThresholds[0],
            [styles.orange]:
              fixScore > colorThresholds[0] && fixScore <= colorThresholds[1],
            [styles.apple]:
              fixScore > colorThresholds[1] && fixScore <= colorThresholds[2],
            [styles.green]: fixScore > colorThresholds[2]
          })}
          d={path}
          strokeWidth={strokeWidth}
          strokeDasharray={strokeDasharray}
        />
      </svg>
      <span className={classnames(styles.badgeScore, 'font-1')}>
        {isNumber ? fixScore : '?'}
      </span>
    </div>
  );
};

export default AffinityBadge;
