import React from 'react';
import { createRoot } from 'react-dom/client';

import readAttribute from 'common/tools/dom/readAttribute';

import EntityTitleActions from 'website/components/EntityTitleActions';
import allocineContainer from 'website/containers/allocineContainer';

export default function () {
  const titleActionContainer = document.getElementById('js-title-actions');

  if (titleActionContainer) {
    const entityId = readAttribute(titleActionContainer, 'data-entity-id');
    const ConnectedTitleActions = allocineContainer(EntityTitleActions);
    const root = createRoot(titleActionContainer);
    root.render(<ConnectedTitleActions entityId={entityId} />);
  }
}
