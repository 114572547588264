import loadScript from 'load-script';

import { DISQUS_SHORTNAME } from 'website/configuration/constants';

const DISQUS_URL = `//${DISQUS_SHORTNAME}.disqus.com/embed.js`;
let loading = false;
export default function (threadId, category, callback) {
  if (loading) {
    return false;
  }
  loading = true;

  loadScript(DISQUS_URL, () => {
    loading = false;
    callback();
  });
}
