import React from 'react';
import { createRoot } from 'react-dom/client';

import readAttribute from 'common/tools/dom/readAttribute';

import ReviewLink from 'website/components/opinion/ReviewLink';
import allocineContainer from 'website/containers/allocineContainer';

import { waitForLoginStatus } from '../user/connection';

export default async function userReviewLink() {
  const positions = document.getElementsByClassName('js-user-review-link');

  if (!positions.length) {
    return;
  }

  Array.from(positions).forEach((position, index) => {
    const entityId = readAttribute<string, undefined>(
      position,
      'data-entity-id'
    );
    if (entityId) {
      const ConnectedReviewLink = allocineContainer(ReviewLink);
      let classes =
        readAttribute<string, undefined>(position, 'data-classes')?.split(
          ' '
        ) ?? [];
      const content = readAttribute(position, 'data-content');
      const openOn =
        readAttribute<string, undefined>(position, 'data-open-on') || null;
      // Do we need to programmatically open the review modal ?
      if (index === 0 && window.location.hash === '#edit-review') {
        classes = [...classes, 'edit-review-auto-open-link'];
      }
      const root = createRoot(position);
      root.render(
        <ConnectedReviewLink
          className={classes.join(' ')}
          entityId={entityId}
          openOn={openOn}
        >
          {content}
        </ConnectedReviewLink>
      );
    }
  });

  // Let's wait for the login status and simulate a click
  await waitForLoginStatus();
  const link = document.getElementsByClassName(
    'edit-review-auto-open-link'
  )[0] as HTMLAnchorElement | undefined;
  if (link) {
    link.click();
  }
}
