export default async function init() {
  const showMoreImgButtons = document.querySelectorAll('.js-fetch-images');

  if (showMoreImgButtons.length) {
    const module = await import(
      /* webpackChunkName: "shots-fetch" */ './shotsFetch.lazy'
    );
    module.createShotsFetch(showMoreImgButtons);
  }
}
