import React from 'react';
import { createRoot } from 'react-dom/client';

import readAttribute from 'common/tools/dom/readAttribute';

import AddToCollectionButton from 'website/components/user/AddToCollectionButton';
import allocineContainer from 'website/containers/allocineContainer';

const userAddToCollection = () => {
  const anchors = document.getElementsByClassName('js-add-to-collection');

  for (const anchor of anchors) {
    const entityId = readAttribute(anchor, 'data-entity-id');

    const ConnectedAddToCollectionButton = allocineContainer(
      AddToCollectionButton
    );

    const root = createRoot(anchor);

    root.render(<ConnectedAddToCollectionButton entityId={entityId} />);
  }
};

export default userAddToCollection;
