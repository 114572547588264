import loadScript from 'load-script';

import readAttribute from 'common/tools/dom/readAttribute';
import { runAfterDeferAssets } from 'common/tools/ui/load-assets';

export default function loadAdgage() {
  const adgagePlace = document.getElementsByClassName('js-adgage');
  if (!adgagePlace.length) {
    return false;
  }

  runAfterDeferAssets(() => {
    const WIDGET_ID = readAttribute(adgagePlace[0], 'data-id');
    const WIDGET_PUBLISHER = readAttribute(adgagePlace[0], 'data-publisher');
    loadScript(`//jsc.adgage.es/s/e/${WIDGET_PUBLISHER}.${WIDGET_ID}.js`);
  });
}
