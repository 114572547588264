import React from 'react';
import { createRoot } from 'react-dom/client';

import readAttribute from 'common/tools/dom/readAttribute';

import FolloweesRating from 'website/components/user/FolloweesRating';
import allocineContainer from 'website/containers/allocineContainer';

export default function () {
  const userFriendsRatings = document.getElementsByClassName(
    'js-user-friends-rating'
  );

  if (userFriendsRatings.length) {
    for (const anchor of userFriendsRatings) {
      const title = readAttribute<string, undefined>(anchor, 'data-title');
      const size = readAttribute<string, undefined>(anchor, 'data-size');
      const entityId = readAttribute<string, undefined>(
        anchor,
        'data-entity-id'
      );

      const ConnectedFolloweesRating = allocineContainer(FolloweesRating);

      const root = createRoot(anchor);
      root.render(
        <ConnectedFolloweesRating
          entityId={entityId}
          size={size}
          title={title}
        />
      );
    }
  }
}
