import loadScript from 'load-script';

import readAttribute from 'common/tools/dom/readAttribute';
import { runAfterDeferAssets } from 'common/tools/ui/load-assets';

export default function loadTaboola() {
  const taboolaPlace = document.getElementsByClassName('js-taboola');
  if (!taboolaPlace.length) {
    return false;
  }

  const TABOOLA_URL = readAttribute(taboolaPlace[0], 'data-url');

  if (!TABOOLA_URL) {
    return false;
  }

  window._taboola = window._taboola || [];
  window._taboola.push({ article: 'auto' });

  runAfterDeferAssets(() => {
    loadScript(TABOOLA_URL, () => {
      /* code provided by Taboola */
      for (const item of taboolaPlace) {
        window._taboola.push(readAttribute(item, 'data-mode'));
      }

      window._taboola.push({ flush: true });
      /* code provided by Taboola */
    });
  });
}
