import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { Maybe, Scalars } from 'types/graphql-api.generated';

import { retrieveFolloweesAverage } from 'common/api/GraphApi';
import UserRating from 'common/components/UserRating';
import trans from 'common/tools/translations/trans';

import onlyIfHasSocial from 'website/components/HoC/onlyIfHasSocial';
import { HoverTooltip } from 'website/components/Tooltip';
import AuthenticatedLink from 'website/components/user/AuthenticatedLink';
import { State } from 'website/reducers';
import { openFolloweesRatingModal } from 'website/services/modal';

import styles from './styles.module.scss';

type FolloweesRatingProps = {
  entityId?: string;
  size?: string;
  title?: string;
};

type PropsFromRedux = ConnectedProps<typeof connector>;

// while waiting to include graph queries from the www project (excluding MAC) on CodeGen
type FolloweesAverageQuery = {
  __typename?: 'Query';
  me?: {
    __typename?: 'Me';
    user?: {
      __typename?: 'User';
      social?: {
        __typename?: 'UserSocialInformation';
        followeesAverageRating?: Maybe<Scalars['Float']['output']>;
        opinionCount?: {
          __typename?: 'OpinionCount';
          totalCount?: Maybe<Scalars['Int']['output']>;
        } | null;
        wantToSeeCount?: {
          __typename?: 'OpinionCount';
          totalCount?: Maybe<Scalars['Int']['output']>;
        } | null;
      } | null;
    } | null;
  } | null;
};

const FolloweesRating = ({
  entity,
  size,
  title,
  user: { loggedIn }
}: FolloweesRatingProps & PropsFromRedux) => {
  const [average, setAverage] = useState(0);
  const [followeesRatingCount, setFolloweesRatingCount] = useState(0);
  const [followeesWantToSeeCount, setFolloweesWantToSeeCount] = useState(0);

  const fetchFolloweesAverage = async () => {
    if (loggedIn) {
      const response: FolloweesAverageQuery = await retrieveFolloweesAverage(
        entity?.id
      );

      setAverage(response.me?.user?.social?.followeesAverageRating || 0);
      setFolloweesRatingCount(
        response.me?.user?.social?.opinionCount?.totalCount || 0
      );
      setFolloweesWantToSeeCount(
        response.me?.user?.social?.wantToSeeCount?.totalCount || 0
      );
    }
  };

  // We cannot use an async function directly inside useEffect. If we ever want to call an async function,
  // we need to define the function outside useEffect and then call it within useEffect
  const fetchData = () => {
    fetchFolloweesAverage();
  };

  // call fetchFolloweesAverage function only when loggedIn variable change
  useEffect(fetchData, [loggedIn]);

  const handleClick = () => {
    openFolloweesRatingModal({
      entity
    });
  };

  const badgeClassname = classNames('stareval-note', {
    'no-rating': !average
  });

  // ugly replace, but it works for all the format used in the current countries,
  // and prevents the need of a polyfill for Intl
  const roundedValue = (Math.round(average * 10) / 10)
    .toString()
    .replace('.', ',');

  const starEvalClassname = `stareval stareval-${size}`;

  const followeesCount = followeesRatingCount + followeesWantToSeeCount;
  const ratingTitleClassName = classNames('rating-title', {
    [styles.friendsRatingLink]: followeesCount
  });

  const rating = (
    <>
      <AuthenticatedLink
        className={ratingTitleClassName}
        onClick={followeesCount ? handleClick.bind(this) : undefined}
        ignoreMailValidation
      >
        {title}
      </AuthenticatedLink>

      <div className={starEvalClassname}>
        <UserRating value={average} readOnly theme="yellow" />
        <span className={badgeClassname}>{average ? roundedValue : '--'}</span>
      </div>

      {
        // If none of the followees gave a rating opinion
        // but one of them gave a wantToSee opinion,
        // the text is displayed.
        !followeesRatingCount && followeesWantToSeeCount ? (
          <span className="stareval-review light">
            {trans('rating.friends-want-to-see', {
              followeesWantToSeeCount,
              count: followeesWantToSeeCount
            })}
          </span>
        ) : null
      }
    </>
  );

  return (
    <HoverTooltip
      theme="blue"
      tooltipContent={
        !average
          ? trans('rating.invite-your-friends')
          : trans('rating.opinions-of-your-friends')
      }
    >
      {rating}
    </HoverTooltip>
  );
};

const mapStateToProps = (state: State, ownProps: FolloweesRatingProps) => ({
  user: state.user,
  entity: ownProps.entityId ? state.data.all[ownProps.entityId] : undefined
});

const connector = connect(mapStateToProps);

export default connector(onlyIfHasSocial(FolloweesRating));
