import { addClass, hasClass, removeClass } from 'common/tools/dom/classes';
import * as events from 'common/tools/dom/events';

function hide(parent) {
  removeClass(parent, 'active');
  events.off(document.body, 'click', hide.bind(null, parent));
}

function show(parent) {
  addClass(parent, 'active');
  events.on(document.body, 'click', hide.bind(null, parent));
}

function toggleMore(event, parent) {
  event.preventDefault();
  event.stopPropagation();

  if (hasClass(parent, 'active')) {
    hide(parent);
  } else {
    show(parent);
  }
}

export default function initMoreTriggerOverlay() {
  const moreOverlayItems = document.getElementsByClassName('js-more-overlay');

  if (moreOverlayItems.length) {
    for (const item of moreOverlayItems) {
      const toggleBtn = item.getElementsByClassName('js-more-overlay-button');

      events.on(toggleBtn, 'click', event => {
        toggleMore(event, item);
      });
    }
  }
}
