export enum AffinityScoreReason {
  // User has not confirm his email.
  ACCOUNT_NOT_ACTIVATED = 'ACCOUNT_NOT_ACTIVATED',

  // API RECO enable to calculate affinity score.
  ONGOING_ALGO = 'ONGOING_ALGO',

  // User has not rated enough movies to be eligible for the calculation of the affinity score.
  NOT_ENOUGH_RATINGS = 'NOT_ENOUGH_RATINGS',

  // User has already rated current movie.
  ALREADY_RATED = 'ALREADY_RATED'
}
