// import store from 'website/store';
// import { getUserTheaters } from 'website/actions/TheaterActions';
import { retrieveUserTheaters } from 'common/api/SecureApi';

import { waitForLoginStatus } from 'website/containers/user/connection';

type Theaters = {
  actions: {
    [key: string]: {
      targetKey: string;
    };
  };
  targets: {
    [key: string]: {
      id: string;
      label: string;
    };
  };
  resultCount: number;
};

const getTheaterIds = (theaters: Theaters): string[] | false => {
  if (!theaters.targets) {
    return false;
  }

  const theatersId: string[] = [];

  Object.entries(theaters.targets).forEach(([key, value]) => {
    if (key.indexOf('theater') !== -1) {
      theatersId.push(value.id);
    }
  });

  return theatersId;
};

const init = async () => {
  await waitForLoginStatus();
  const user = await waitForLoginStatus();

  if (!user.loggedIn) {
    return false;
  }

  const theaters: Theaters = await retrieveUserTheaters();
  // todo add theaters in localStorage and see how to export a function to handle showtimeRedirect links :)
  return getTheaterIds(theaters);
};

export default init;
