// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Y6Y7qDPGTKlZvYR51Q8i{position:relative;display:inline-block;width:2.5rem;height:2.5rem;vertical-align:middle}.nhyU8UNwIF9FRTHJsbT3{position:absolute;top:0;left:0;width:100%;height:100%;cursor:pointer}.cEJReij9vjAzo0ztcmLG{fill:rgba(0,0,0,.8)}.AaFkdx7YMnTSBlsS6bzt{display:none;fill:none;stroke:#727272}.LFWsP6wgrxR5289yPbng{display:none;animation:kR17FuvAFHLFeZX2Uqfe 1s ease-out forwards;fill:none;stroke-linecap:round}.dZ6Qx4goXRfseGsQ2h8g{font-size:1.03125rem;font-weight:700;line-height:2.5rem;position:absolute;top:0;left:0;width:100%;cursor:pointer;text-align:center;color:#fff}.tDmuFJgIgpZxGGS74QM8{stroke:#ff304c}.OwlyYDdVzHZCPjMEIK3W{stroke:#ff9b00}.YrSjau59FQs7oyfWZlwz{stroke:#bbd140}.El7vKVCD0cte69WzHfuk{stroke:#48d987}.hThj3i4V4ys4v7WIiqEU{display:block}@keyframes kR17FuvAFHLFeZX2Uqfe{0%{stroke:#727272;stroke-dasharray:0 103.6725575685}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"padding": "3.5",
	"diameter": "40",
	"strokeWidth": "3",
	"strokeDiameter": "33",
	"strokeRadius": "16.5",
	"circumference": "103.6725575685",
	"badge": "Y6Y7qDPGTKlZvYR51Q8i",
	"badgeSvg": "nhyU8UNwIF9FRTHJsbT3",
	"badgeBg": "cEJReij9vjAzo0ztcmLG",
	"badgeProgressRail": "AaFkdx7YMnTSBlsS6bzt",
	"badgeProgressRing": "LFWsP6wgrxR5289yPbng",
	"progress": "kR17FuvAFHLFeZX2Uqfe",
	"badgeScore": "dZ6Qx4goXRfseGsQ2h8g",
	"red": "tDmuFJgIgpZxGGS74QM8",
	"orange": "OwlyYDdVzHZCPjMEIK3W",
	"apple": "YrSjau59FQs7oyfWZlwz",
	"green": "El7vKVCD0cte69WzHfuk",
	"active": "hThj3i4V4ys4v7WIiqEU"
};
export default ___CSS_LOADER_EXPORT___;
