import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import AffinityBadge, { thresholds } from 'common/components/AffinityBadge';
import { AffinityScoreReason } from 'common/constants/Affinities';
import { TrackingEventNames } from 'common/constants/trackingEventsNames';
import eventEmitter from 'common/services/events/eventEmitter';
import hasTouch from 'common/tools/dom/getTouchBrowser';

import onlyIfHasSocial from 'website/components/HoC/onlyIfHasSocial';
import { HoverTooltip } from 'website/components/Tooltip';
import AuthenticatedLink from 'website/components/user/AuthenticatedLink';
import { waitForLoginStatus } from 'website/containers/user/connection';
import { State } from 'website/reducers';
import { Entity } from 'website/types';

import AffinityTooltip, { TooltipType } from './AffinityTooltip';
import styles from './styles.module.scss';

const {
  ACCOUNT_NOT_ACTIVATED,
  ALREADY_RATED,
  NOT_ENOUGH_RATINGS,
  ONGOING_ALGO
} = AffinityScoreReason;

type Props = { entityId: string };

const mapStateToProps = (state: State, ownProps: Props) => {
  const { entityId } = ownProps;

  return { entity: state.data.all[entityId] as Entity };
};

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const AffinityScore = ({ entity }: Props & PropsFromRedux) => {
  const [displayed, setDisplayed] = useState(false);
  const [score, setScore] = useState<undefined | number>();
  const [reasonsList, setReasonsList] = useState<string[]>([]);
  const [tooltipType, setTooltipType] = useState<undefined | TooltipType>();
  const [tooltipVisible, setTooltipVisible] = useState(false);

  useEffect(() => {
    // wait to know if the user is logged to display affinity badge
    // otherwise it could disappear after the loggedIn status change
    waitForLoginStatus().then(user => {
      if (!entity) {
        return;
      }
      let newTooltipType = TooltipType.NotEligible;

      if (user.loggedIn) {
        const averageResponse =
          'userAffinity' in entity && entity?.userAffinity
            ? entity.userAffinity
            : {
                reason: [],
                affinityScore: null
              };

        const { reason, affinityScore } = averageResponse;
        const reasons = reason || [];

        setReasonsList(reasons);

        if (reasons.includes(ALREADY_RATED)) {
          // hide Affinity Badge if the user has already rated
          setDisplayed(false);
          return;
        }

        // to make the difference between 0 and null
        if (typeof affinityScore === 'number') {
          // request return value between 0 and 1 already rounded
          setScore(affinityScore);

          if (affinityScore <= thresholds[0]) {
            newTooltipType = TooltipType.BadScore;
          } else if (
            affinityScore > thresholds[0] &&
            affinityScore <= thresholds[1]
          ) {
            newTooltipType = TooltipType.PoorScore;
          } else if (
            affinityScore > thresholds[1] &&
            affinityScore <= thresholds[2]
          ) {
            newTooltipType = TooltipType.GoodScore;
          } else {
            newTooltipType = TooltipType.ExcellentScore;
          }
        } else if (reasons.includes(ONGOING_ALGO)) {
          newTooltipType = TooltipType.OngoingAlgo;
        } else if (reasons.includes(NOT_ENOUGH_RATINGS)) {
          newTooltipType = TooltipType.NotEnoughRatings;
        }
      } else {
        newTooltipType = TooltipType.Login;
      }

      setDisplayed(true);
      setTooltipType(newTooltipType);
    });
  }, [entity]);

  if (!entity) return null;

  // show badge if no ratings
  if (!displayed) {
    return null;
  }
  const badge = (
    <AuthenticatedLink
      trackingContext={{
        eventAction: 'impression_affinity_score',
        entityTypename: entity.typename
      }}
    >
      <AffinityBadge affinityScore={score} />
    </AuthenticatedLink>
  );

  if (reasonsList.includes(ACCOUNT_NOT_ACTIVATED)) {
    return badge;
  }

  const tooltipContent = (
    <AffinityTooltip tooltipType={tooltipType} entity={entity} />
  );

  const handleTracking = (eventType: 'touch' | 'hover', visible: boolean) => {
    // prevent send tracking at every touch event keep tooltip visible
    if (visible && visible !== tooltipVisible) {
      eventEmitter.emit(TrackingEventNames.AFFINITY_TOOLTIP_EVENT, {
        entity,
        eventType
      });
    }
    setTooltipVisible(visible);
  };

  const actionProps = hasTouch()
    ? {
        onTargetClick: handleTracking.bind(null, 'touch')
      }
    : {
        // Desktop (no touch) devices UX
        onMouseEnter: handleTracking.bind(null, 'hover')
      };

  return (
    <HoverTooltip
      className={styles.affinityScoreTooltip}
      theme="blue"
      tooltipContent={tooltipContent}
      entity={entity}
      {...actionProps}
    >
      {badge}
    </HoverTooltip>
  );
};

export default onlyIfHasSocial(connector(AffinityScore));
